import FiveGridLine_IronLineComponent from '@wix/thunderbolt-elements/src/components/FiveGridLine/viewer/skinComps/IronLine/IronLine.skin';


const FiveGridLine_IronLine = {
  component: FiveGridLine_IronLineComponent
};


export const components = {
  ['FiveGridLine_IronLine']: FiveGridLine_IronLine
};

